<template>
  <div class="page" style="height:calc(100vh - 70px);">
    <div class="card login-card">
      <div class="card-body">
        <div class="text-center pt-5 pb-3">
          <img src="@/assets/pb_logo_1_remove_bg_100x125.png">
        </div>
        <b-form @submit.prevent="onSubmit">
          <div class="biz-form-group">
            <div class="biz-form-body">
              <b-form-group class="biz-input-group">
                <b-form-input type="email" v-model="form.email" name="email" placeholder="メールアドレス">
                </b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="biz-form-group">
            <div class="biz-form-body">
              <b-form-group class="biz-input-group">
                <b-form-input type="password" v-model="form.password" name="password" placeholder="パスワード"></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="text-center mt-4">
            <j-button buttonType="submit" class="login-button" variant="primary">ログイン</j-button>
          </div>
          <div class="text-center mt-2">
            <router-link to="/reset-password" class="small reset-password-link">メールアドレス、パスワードをお忘れの方はこちら</router-link>
          </div>
        </b-form>
        <fieldset v-if="demoAccounts != null && demoAccounts.length > 0" class="my-3">
          <a class="account-link p-2" style="font-weight:bold; background: #47724d;" v-b-toggle.demo-login-accounts>
            サプライヤ
            <font-awesome-icon icon="caret-down" class="float-right mt-1 mr-2"/></a>
          <b-collapse id="demo-login-accounts">
            <a href="#" class="account-link my-2 p-2" v-for="account in demoAccounts" :key="account.username" @click.prevent="demoLogin(account)">{{account.name}}</a>
          </b-collapse>
        </fieldset>
      </div>
    </div>
  </div>
</template>

<script>
  import Login from '@/model/Login.js';
  export default {
    components: {},
    data(){
      return {
        form: {
          email: '',
          password: ''
        },
        redirectUrl: this.$route.query.redirect || '/supplier/manage',
        demoAccounts: Login.getDemoLoginData(),
      }
    },
    created(){
      if(process.env.VUE_APP_DEMO_MODE !== 'production' &&
        this.$route.query.auto === 'true'){
        const account = {
          username: this.$route.query.username,
          password: this.$route.query.pwd
        };
        this.demoLogin(account);
      }
    },
    computed: {
    },
    beforeRouteLeave (to, from, next) {
      this.$bvToast.hide('LockedAccount');
      next();
    },
    methods: {
      demoLogin(account){
        this.form.email = account.username;
        this.form.password = account.password;
        this.onSubmit();
      },
      onSubmit() {
        Login.login(this.form).then(result => {
          if(result.data.modifyPasswordFlg){
            this.$store.commit("passwordSetup", {
              accessToken: result.headers.authorization,
              user: result.data,
            });
            this.$router.push({ name: "SetupPassword", params: { temporaryPassword: this.form.password }});
          }
          // Direct to RequestResetPassword page
          // Copy from commit: "DEV-2023-5345 : change password rule" in develop branch
          else if (result.data.isPasswordExpired) {
            this.$router.push({ name: "RequestResetPassword", params: { 
              accessToken: result.headers.authorization,
              user: result.data,
            }});
          }else{
            this.$store.commit("login", {
              accessToken: result.headers.authorization,
              user: result.data,
            });
            if (result.data.status.accountCreated) {
              if(this.redirectUrl){
                console.log(this.redirectUrl);
                this.$router.push(this.redirectUrl);
              } else this.$router.push({ name: "MainMenu" });
            } else {
              this.$router.push({ name: "InviterPolicy" });
            }
          } 
        }).catch(reason => { 
          if (reason.response.status === 401) {
            this.$store.commit("twoFactorInvalid", reason.response.data);
            this.$router.push({ name: "ValidateDevice" });
          } else if(reason.response.headers.exception === 'LockedException'){
            console.error(reason);
            // Update message
            // Copy from commit: "DEV-2023-5345 : change password rule" in develop branch
            this.$errorMessageHtml('ログイン制限回数を超えたため、アカウントがロックされています。しばらくお待ちいただくか、<a href="reset-password">こちら</a>よりパスワード再設定を行ってください。','LockedAccount',{noAutoHide: true});
          }else { 
            this.$errorMessage("ユーザーID、パスワードの何れかが正しくありません。", {always:true, reason});
          }
        })

      }
    }
  }
</script>

<style lang="scss" scoped>
  .login-card{
    background:rgba(35, 47, 62, 1);
    max-width: 450px;
    margin: 10px auto;
    border-radius: 1rem;
    .card-body {
      padding: 1rem 2.5rem 1rem 2.5rem;
      input {
        padding: .375rem .75rem;
        height: 38px;
        line-height: 1.5;
      }
    }
  }
  .login-button{
    width: 100%;
    padding: .8rem 1rem;
    background: #1a9bd5;
    border:none;
    box-shadow: none;
    transition: all .2s ease-in;
    &:hover{
      background: #559f59;
    }
  }
  .reset-password-link{
    color: #fff;
    &:hover{ text-decoration: underline;}
  }
  .account-link{
    color: #fff;
    display:block;
    text-decoration: none;
    background: rgba(255, 255, 255, 0.1);
    &:hover{ 
      background: rgba(255, 255, 255, 0.2);
    }
    
  }
</style>